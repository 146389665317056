:root {
	--bs-blue: #3f6ad8;
	--bs-primary: #3f6ad8;
}

body {
	background: #EAEDEF;
}

.font-weight-bold {
	font-weight: bold !important;
}